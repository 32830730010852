import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Chamando UI Amplify
import {Home} from './ui-components';

import awsExports from './aws-exports';
Amplify.configure(awsExports);


export default function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        //<Main />
        <main>
          <h1>Olá {user.attributes.name}</h1>
          <Home />
          <button onClick={signOut}>Sign out</button>
        </main>
      )}
    </Authenticator>
  );
}
